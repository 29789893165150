import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Concursos',
    url: '/concurso',
    icon: 'icon-chart'
  },
  {
    name: 'Tabla de Perfiles',
    url: '/perfiles',
    icon: 'icon-chart'
  },
  {
    name: 'Estadisticas',
    url: '/estadisticas',
    icon: 'icon-chart'
  },
  {
    name: 'Comparacion General',
    url: '/comparacion',
    icon: 'icon-chart'
  },
  {
    name: 'Ganadores',
    url: '/ganadores',
    icon: 'icon-people'
  }

  ];
