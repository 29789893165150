import { Component, OnInit } from '@angular/core';
import { Login } from '../../models/login';
import swal from 'sweetalert2';
import { LoginService } from '../../services/login.service';
import {Router} from "@angular/router"

@Component({
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  objLogin: Login;
  ngOnInit() {    
    if(sessionStorage.getItem('aToken')){
      this.router.navigate(['/concurso'])
    }
  }
  constructor(private sessionStorage:LoginService,private router:Router) {
    this.objLogin = new Login();
    this.objLogin.username = '';
    this.objLogin.password = '';
  }
  fillObjLogin(e) {
    
    if (this.objLogin.username.length <= 0 || this.objLogin.username === null
      || this.objLogin.password === '' || this.objLogin.password === null) {
      swal(
        'Error!',
        'You have to fill the inputs ',
        'error'
      );
    }else{
    this.sessionStorage.getLogin(this.objLogin).subscribe(response=>{
      if(response['access']!=null){
        sessionStorage.setItem('aToken', response['access']);
        sessionStorage.setItem('rToken', response['refresh']);
        this.router.navigate(['/concurso'])
      }

    },err=>{
      if(err.status==401){
        swal(
          'Error!',
          'Invalid credentials  ',
          'error'
        );        
      }
    });
    }
  }
}

