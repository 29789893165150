import {Component, OnInit} from '@angular/core';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  ngOnInit(){
    if(sessionStorage.getItem('aToken')=== null){
      this.router.navigate(['/login'])
    }
  }
  constructor(private router:Router){
  }
  logout(){    
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }
  public sidebarMinimized = false;
  public navItems = navItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}